// This should be in px to prevent resize according to font settings
@nei-controls-size: 40px;
@nei-controls-icon-font-size: 20px;
@nei-controls-padding: 8px;
@nei-controls-offset: 4px;
@nei-controls-bg: @_white_color;
@nei-controls-bg-hover: darken(@nei-controls-bg, 4%);
@nei-controls-box-shadow: 0 0 8px fade(@global-text-color, 16%);
@nei-controls-z-index: 14; // Should be more than value in .nei-widget-actions {}

.nei-widget-controls {
  display: flex;
  position: absolute;
  bottom: 100%;
  margin-bottom: -(@nei-controls-size + 2 * @nei-controls-padding + @nei-controls-offset);
  opacity: 0;
  transform: rotateX(90deg);
  transition: @global-hover-transition;

  .nei-widget:hover &,
  .nei-widget.freeze-controls & {
    z-index: @nei-controls-z-index;
    margin-bottom: -(@nei-controls-size + 2 * @nei-controls-padding + @nei-controls-offset);
    opacity: 1;
    transform: none;
  }

  .nei-widget.editing & {
    &,
    &:hover {
      margin-bottom: @nei-controls-offset;
      opacity: 1;
      transform: none;
    }
  }

  .hide-controls &,
  .has-buffer & {
    opacity: 0 !important;
    display: none;
    transform: rotateX(90deg);
    transition: @global-hover-transition;
  }

  [dir=ltr] &,
  [dir=rtl] [dir=ltr] & {
    right: @nei-controls-offset;
    left: auto;
  }

  [dir=rtl] & {
    left: @nei-controls-offset;
    right: auto;
  }

  .control-group {
    display: flex;
    margin: 0 @nei-controls-padding @nei-controls-padding;
  }

  .control {
    cursor: pointer;
    display: inline-block;
    width: @nei-controls-size;
    height: @nei-controls-size;
    text-align: center;
    background-color: @nei-controls-bg;
    box-shadow: @nei-controls-box-shadow;
    border: 0 none;
    color: @global-text-color;
    .button-border-radius(@nei-controls-size / 16);
    transition: background-color .2s linear;

    &:hover,
    &:focus,
    &.active {
      background-color: @nei-controls-bg-hover;
    }

    .md-icon {
      font-size: @nei-controls-icon-font-size;
      line-height: @nei-controls-size;
      vertical-align: baseline;

      &.md-edit_note {
        transform: scale(1.25);
      }
    }

    svg {
      fill: currentColor;

      [stroke] {
        stroke: @nei-controls-bg-hover !important;
      }
    }

    & + .control {
      margin-inline-start: @nei-controls-padding / 2;
    }
  }

  .edit-anchor {
    svg {
      width: @md-icon-size;
      height: @md-icon-size;
    }

    .anchor-edit-icon {
      display: none;
    }

    .nei-widget-has-anchor & {
      .anchor-add-icon {
        display: none;
      }

      .anchor-edit-icon {
        display: inline-block;
      }
    }
  }
}

