@mtx-api-page-top-bottom-nav-link-padding-y: @global-margin-medium;
@mtx-api-page-top-bottom-nav-link-padding-x: @global-margin-small;
@mtx-api-page-top-bottom-nav-title-font-size: @global-font-size;
@mtx-api-page-top-bottom-nav-title-line-height: @global-line-height;

.mtx-api-page-top-bottom-nav {
  box-shadow: 0 0 2px fade(#000000, 6%); // The same as @page-sidebar-box-shadow
  transition: all .15s ease-in-out;

  .nav-link,
  .nav-link-learning-path {
    display: block;
    position: relative;
    padding: @mtx-api-page-top-bottom-nav-link-padding-y @mtx-api-page-top-bottom-nav-link-padding-x;
    .text-ellipsis;
    text-align: center;

    &:before {
      .material-icon();
      position: static;
      color: inherit;
    }

    .title {
      font-size: @mtx-api-page-top-bottom-nav-title-font-size;
      line-height: @mtx-api-page-top-bottom-nav-title-line-height;
      transition: color .15s ease-in-out;

      @media all and (min-width: @breakpoint-medium) {
        font-weight: @global-font-weight-semibold;
      }
    }

    @media all and (min-width: @breakpoint-medium) {
      &.next .md-icon,
      &:before {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .nav-link,
  .nav-link-learning-path {
    &.previous:before {
      content: '\e316'; // md-keyboard_arrow_up
    }

    &.next:before {
      content: '\e313'; // md-keyboard_arrow_down
    }

    // Restricted icon has other positioning than arrow
    &.restricted {
      &:before {
        content: none;
      }
    }

    @media all and (min-width: @breakpoint-medium) {
      &.next {
        position: relative;
        padding-bottom: @mtx-api-page-top-bottom-nav-link-padding-y + @md-icon-size;

        &:before {
          position: absolute;
          bottom: @mtx-api-page-top-bottom-nav-link-padding-y;
          left: calc(50% - @md-icon-size / 2);
        }

        &.restricted {
          padding-bottom: @mtx-api-page-top-bottom-nav-link-padding-y;

          &:before {
            content: none;
          }
        }
      }
    }
  }
}
