@mtx-users-avatar-size: 3rem;

.mtx-users {
  &.compact {
    .created-cell,
    .full-name-cell .external-id {
      display: none;
    }

    .table-action-icon-cell {
      .view-mode-btn .md-icon:after {
        content: '\efe8'; // md-calendar_view_week
      }
    }
  }

  &.compact:not(.marketplace) {
    .last-login-cell,
    .organizations-cell {
      display: none;
    }
  }

  .users {
    font-size: @table-font-size;
  }

  .header {
    .row > * {
      vertical-align: top;
    }

    .row > .cell {
      font-weight: @table-th-font-weight;
    }

    .table-action-icon-cell {
      min-width: @md-icon-size + @table-cell-padding * 2;

      &:last-child {
        min-width: @md-icon-size + @table-cell-padding;
      }
    }
  }

  .body {
    word-break: break-word;

    .row > * {
      vertical-align: top;
    }

    .table-action-icon-cell {
      min-width: @button-height + @global-margin-small;
      padding-inline-start: @global-margin;

      &:not(:last-child) {
        padding-inline-end: 0;
      }
    }
  }

  .row {
    display: table;
    width: 100%;
    padding: @global-margin 0;
  }

  .cell {
    display: table-cell;
    padding: 0 @table-cell-padding;

    &:first-child {
      padding-inline-start: 0;
    }

    &:last-child {
      padding-inline-end: 0;
    }

    &.checkbox-cell {
      vertical-align: middle;
    }
  }

  .full-name-cell {
    .avatar {
      min-width: @mtx-users-avatar-size;

      &,
      img {
        .circle(@mtx-users-avatar-size);
      }
    }

    .text > div + div {
      margin-top: @global-margin-mini;
    }
  }

  .activities-cell,
  .organizations-cell {
    [data-show-more] {
      max-height: @table-font-size * @global-line-height * 2;
    }
  }

  .table-action-icon-cell {
    .view-mode-btn .md-icon {
      font-size: 1.25rem;
    }
  }

  .filters {
    .search {
      .input-search-icon();

      input {
        width: 100%;
      }

      @media all and (max-width: @breakpoint-small-max) {
        float: none;
        overflow: hidden;
      }
    }
  }

  .mtx-load-more-button {
    margin-top: 2rem;
  }

  @media all and (min-width: @breakpoint-xlarge) {
    &.compact:not(.marketplace) {
      .full-name-cell {
        width: 31%;
      }

      .role-cell {
        width: 25%;
      }

      .activities-cell {
        width: 39%;
      }
    }

    &.compact.marketplace {
      .full-name-cell {
        width: 40%;
      }

      .role-cell {
        width: 22%;
      }

      .last-login-cell {
        width: 33%;
      }
    }

    &.detailed:not(.marketplace) {
      .full-name-cell {
        width: 31%;
      }

      .role-cell,
      .created-cell,
      .last-login-cell {
        width: 11%;
      }

      .activities-cell {
        width: 19%;
      }

      .organizations-cell {
        width: 12%;
      }
    }

    &.detailed.marketplace {
       .full-name-cell {
         width: 30%;
       }

       .role-cell {
         width: 15%;
       }

       .create-cell,
       .last-login-cell {
         width: 25%;
       }
     }
  }

  @media all and (min-width: @breakpoint-medium) and (max-width: @breakpoint-large-max) {
    &.compact:not(.marketplace) {
      .full-name-cell,
      .activities-cell {
        width: 36%;
      }

      .role-cell {
        width: 24%;
      }
    }

    &.detailed:not(.marketplace) {
      .full-name-cell {
        width: 28%;
      }

      .role-cell {
        width: 20%;
      }

      .activities-cell,
      .organizations-cell {
        width: 23.5%;
      }
    }

    &.marketplace {
      .header .table-action-icon-cell .view-mode-btn {
        display: none;
      }

      &.compact,
      &.detailed {
        .full-name-cell {
          width: 40%;
        }

        .role-cell {
          width: 25%;
        }

        .last-login-cell {
          width: 30%;
        }
      }
    }

    &.compact,
    &.detailed {
      .created-cell {
        display: none;
      }

      &:not(.marketplace) {
        .last-login-cell {
          display: none;
        }
      }
    }
  }

  @media all and (max-width: @breakpoint-medium) {
    .full-name-cell .external-id {
      display: none;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .filters {
      clear: both;
    }

    .header .table-action-icon-cell .view-mode-btn,
    .created-cell,
    .last-login-cell,
    .activities-cell,
    .organizations-cell {
      display: none;
    }
  }

  @media all and (min-width: @breakpoint-small) and (max-width: @breakpoint-small-max) {
    .full-name-cell {
      width: 57.5%;
    }

    .role-cell {
      width: 37.5%;
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    #mtx-api-filter {
      float: none;
      margin: 0 0 @global-margin;
    }

    .full-name-cell {
      width: 95%;
    }

    .role-cell {
      display: none;
    }
  }
}
