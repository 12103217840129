// Buttons
input[type=submit],
button {
  &:not(:disabled) {
    cursor: pointer;
  }
}

.field-widget-file-generic table td input.form-submit {
  font-size: 12px;
}

.uk-button + .uk-button {
  margin-inline-start: @global-margin-small;
}
