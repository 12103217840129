.mtx-quick-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: @global-margin-small;

  & > * {
    margin-bottom: @global-margin-small;

    [dir=ltr] & {
      margin-left: @global-margin-large;
    }

    [dir=rtl] & {
      margin-right: @global-margin-large;
    }
  }

  a {
    transition: color .15s ease-in-out;
  }
}
